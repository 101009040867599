.layout-menu {
    background-color: #0D1E67;
}

.layout-menu .menu-item a.active,
.layout-menu .menu-item a.active:hover,
.tableHeader {
    background: #364b7e;
    color: #fff;
}

.layout-menu .menu-item a:hover,
.text-bg-primary {
    background-color: #364b7e;
}

.swal2-styled.swal2-confirm {
    background-color: #364b7e !important;
}

.layout-menu button.menu-toggle {
    border-color: #364b7e;
    background: #364b7e !important;
    color: #fff;
}

.swal2-actions .swal2-styled {
    border-color: #364b7e !important;
}

#swal2-title {
    font-size: 25px;
    font-weight: 500;
}

/* Overright */
a,
.planA .price .amount {
    color: #364b7e;
}

.swal2-cancel {
    color: #364b7e !important;
}

.btn-primary {
    background: #364b7e;
}

.Toastify__toast-theme--colored.Toastify__toast--success,
.modal-content .modal-body button.btn-close {
    background: #364b7e !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #fafafa;
}

.userDropdown li a:hover {
    background: #fafafa;
}

.layout-navbar-wrapper {
    background: #fff;
}