h1, h2, h3, h4, h5, h6,
.table th,
.menu-item a {
    font-family: 'Oswald', sans-serif;
}
.table td {
    font-size: 13px;
}
.table th {
    font-size: 15px;
}
.table th {
    font-weight: 500;
}

/*  */
.layout-page,
.layout-navbar,
.layout-menu button.menu-toggle {
    transition: 0.2s ease-in-out;
}
.layout-menu {
    transition: transform 0.2s ease-in-out;
}