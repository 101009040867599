.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: .5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 1.5rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: .5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 1.5rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.box-shadow-1 {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.pl-2 {
    padding-left: 0.5rem !important;
}

.pr-2 {
    padding-right: 0.5rem !important;
}

.pl-3 {
    padding-left: 1rem !important;
}

.pr-3 {
    padding-right: 1rem !important;
}

.no-bg {
    background: none !important;
}

.no-border {
    border: none !important;
}