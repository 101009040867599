.fancy-heading {
    border-bottom: solid 1px black;
    padding-bottom: 10px;
    font-size: 1.688rem !important;
    color: #5c5c5c;
    font-weight: 200 !important;
    margin: 0px !important;
    width: 90%;
}


.fancy-pragh {
    padding-top: 10px;
    font-size: 0.75rem;
    color: #364B7F !important;
    margin: 0px !important;

}

@media (min-width: 768px) {
    .flinksconnect {
        width: 100%;
    }
}

.card.loadingCard.loadingCardOverlap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.create_entity_custom {
    background-color: #364B7F;
}

.create_entity_custom:hover {
    background-color: #364B7F !important;
}

.create_entity_custom span {
    color: #fff;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    font-weight: 300;
    cursor: pointer;
}


.create_entity_custom:hover span {
    color: #fff !important;
}

.card.loadingCard.loadingCardOverlap.inlineLoading {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.conHeader {
    display: flex;
    justify-content: space-between;
}

.conHeader .time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.text-bg-success.light {
    background-color: #E1FDED !important;
    color: #269559 !important;
}

.all_bank_data {
    border: 3px solid #364B7F;
    width: 100%;
    margin-top: 1.75rem;
    border-radius: 15px;
}

.bank_img {
    height: 80px;
    display: flex;
    margin: 0px 50px;
    justify-content: space-between;
}

.active_btn {
    width: 65px;
    height: 25px;
    border-radius: 5px;
    background-color: #E1FDED;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #269559;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.813rem;
    letter-spacing: 1px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
}

.reconnected_btn {
    width: 110px;
    height: 25px;
    border-radius: 5px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.813rem;
    letter-spacing: 1px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.last_seen p {
    font-size: 0.875rem;
    margin-top: 0.3rem !important;
}

.icons {
    display: flex;
    margin-top: 0.75rem;
    gap: 20px;
}

.select {
    width: 60px;
    height: 20px;
    border-radius: 12px;
    background-color: #364B7F;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.select_rotate i {
    transform: rotate(90deg) !important;

}

.select i {
    transform: rotate(-90deg);
}

.select i.rotate {
    transform: rotate(90deg);
}

.fa-ellipsis-vertical {
    color: #364B7F;
}

.table {
    width: 95% !important;
    margin: auto;
    margin-bottom: 20px;
}

.table thead {
    background-color: #364B7F;
    color: #fff;
}

.table tbody {
    background-color: #E9EBF1;
    color: #000;
}

.table tbody td {
    border-right: 1px solid #364B7F;
}

.table tbody td:last-child {
    border-right: 0;
}

.table tbody tr {
    border-bottom: 1px solid #364B7F;
}

.table tbody tr:last-child {
    border-bottom: 0px solid transparent;
}

.table thead th {
    padding: 5px 20px !important;
}


.table tbody td {
    padding: 10px 20px !important;
}


.link span {
    width: 65px;
    height: 25px;
    border-radius: 12px;
    background-color: #E1FDED;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #364B7F;
    text-transform: capitalize;
    font-size: 0.75rem;
    font-weight: 600;
    cursor: pointer;
}

.link_2 span {
    width: 80px;
    height: 25px;
    border-radius: 12px;
    background-color: #ECA7FF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #364B7F;
    text-transform: capitalize;
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;
}

.entity_btn {
    display: flex;
    justify-content: end;
    gap: 15px;
    padding-top: 55px;
}

.create_entity {
    width: 250px;
    height: 55px;
    border: 2px solid #364B7F;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.create_entity:hover {
    background-color: rgb(223, 230, 248);
}

.create_entity a {
    text-decoration: none;
    color: #364B7F;
    font-size: 1.125rem;
    font-weight: 700;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.create_entity_fill {
    background-color: #364B7F;
}

.create_entity_fill:hover {
    background-color: #364B7F;
}

.create_entity_fill a {
    color: #fff;
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    font-weight: 600;
}


.create_entity_fill:hover a {
    color: #fff;
}

.main-nav {
    width: 366px;
    height: 100vh;
    background-color: #E9EBF1;
    display: flex;
    justify-content: center;
}

.navigation {
    width: 285px;
    height: calc(100vh -30px);
    background-color: #364B7F;
    margin: 20px 0px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
}

.logo {
    width: 100% !important;
    height: 30px;
    margin-top: 4.5rem;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.logo img {
    width: 70%;
    height: 110px;
}

.menu {
    position: relative;

}

.menu ul {
    margin-top: 1.25rem;
    display: flex;
    /* 
    justify-content: start !important;
    flex-direction: column; */
}

.menu ul li {
    list-style: none;
    width: 100%;
    margin: 0 2rem;
}

.menu ul li a {
    line-height: 25px;
    color: #fff !important;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 1rem;
    z-index: 11 !important;
}

.nav-link:hover {
    color: #C3D831 !important;
}

.nav-link.active {
    color: #C3D831 !important;
    font-weight: 700;
}

.account {
    display: flex;
    gap: 20px;
    align-items: center;
    position: absolute;
    width: 215px;
    bottom: 30px;
    left: 15px;
}

.account-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-img img {
    object-fit: cover;
    width: 100%;
    border-radius: 50%;
    height: 100%;

}

.information p {
    color: #fff;
    text-transform: capitalize;
    font-size: 0.875rem;
    margin: 0px;
}

.information a {
    text-decoration: none;
    color: #30B8FF;
    text-transform: capitalize;
    font-size: 0.875rem;
    margin: 0px;
}


.information a .fa {
    font-size: 0.5rem;
}

.page-content,
.main {
    width: 88%;
}

.heading {
    width: 100%;
    height: 0px;
    margin: 0px;
    padding: 45px 0px;

}

.heading p {
    display: flex;
    justify-content: end;
    text-transform: capitalize;
    color: #4F618F;
    font-weight: 600;
    font-size: 1.375rem;
}

.net_worth {
    width: 100%;
    border: 4px solid #394C7B;
    height: 200px;
    border-radius: 16px;
    margin: 10px 0px 30px 0px;
    position: relative;
}

.worth_1 {
    width: 50%;
}

.bars {
    width: 150px;
    height: 212px;
}

.bar-1 {
    width: 50px;
    height: 160px;
    background-color: #A5A8F9;
    bottom: 10px;
    position: absolute;
    left: 15px;

}

.bar-2 {
    width: 50px;
    height: 90px;
    background-color: #B774E0;
    bottom: 10px;
    position: absolute;
    left: 75px;
}

.bar-3 {
    width: 50px;
    height: 160px;
    background-color: #30B8FF;
    bottom: 10px;
    position: absolute;

}

.bar-4 {
    width: 50px;
    height: 90px;
    background-color: #006C9E;
    bottom: 10px;
    position: absolute;
    margin-left: 3.8rem;
}

.net_worth_information {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    height: 193px;
}

.net_worth_information h6 {
    color: #484CBE;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 1.135rem;
    margin: 0px;
    line-height: 27px;
}

.net_worth_information_2 {
    margin-left: 0px;
}

.net_worth_information_2 h6 {
    color: #0085CA;

}

.subAmount {
    height: auto;
    display: flex;
    align-items: center;
}

.subAmount .bullet {
    width: 15px;
    height: 15px;
    background-color: #A5A8F9;
    border-radius: 4px;
}

.subAmount p {
    margin: 0px;
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    color: #A5A8F9;
    font-weight: 600;
    font-size: 1rem;
}


.subAmount_2 .bullet {
    width: 15px;
    height: 15px;
    background-color: #B774E0;
    border-radius: 4px;
}

.subAmount_2 p {
    margin: 0px;
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    color: #B774E0;
    font-weight: 600;
    font-size: 1rem;
}

.subAmount_business {
    height: auto;
    display: flex;
    align-items: center;
}

.subAmount_business .bullet {
    width: 15px;
    height: 15px;
    background-color: #30B8FF;
    border-radius: 4px;
}

.subAmount_business p {
    margin: 0px;
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    color: #30B8FF;
    font-weight: 600;
    font-size: 1rem;
}


.subAmount_business_2 .bullet {
    width: 15px;
    height: 15px;
    background-color: #006C9E;
    border-radius: 4px;
}

.subAmount_business_2 p {
    margin: 0px;
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    color: #006C9E;
    font-weight: 600;
    font-size: 1rem;
}

.investment {
    width: 100%;
    margin: 0px 0px 30px 0px;
    display: flex;
    gap: 20px;
}


.number_tm {
    width: 25%;
    height: 280px;
    border-radius: 20px;
    border: 5px solid #394C7B;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.measure_worth {
    width: 50%;
    height: 280px;
    border-radius: 20px;
    border: 5px solid #394C7B;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.measure_worth::before {
    position: absolute;
    top: 0px;
    left: 0px;
    content: '';
    width: 100%;
    height: 20px;
    background-color: #364B7F;
    border-radius: 10px 10px 0px 0px;

}

.chart {
    width: 100%;
    margin: 0px 0px 40px 0px;
}

.trends_chart {
    width: 100%;
    height: 320px;
    border-radius: 20px;
    border: 5px solid #394C7B;
}

.number {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #364B7F;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    padding: 10px;
    position: relative;
}

.progress-bars {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background:
        radial-gradient(closest-side, white 79%, transparent 80% 100%),
        conic-gradient(#74B7FF 50%, #E5E5E5 0);
    display: flex;
    align-items: center;
}

.inrease_invest {
    background:
        radial-gradient(closest-side, white 79%, transparent 80% 100%),
        conic-gradient(#76F000 32%, #E5E5E5 0);
}

.number_tm h6 {
    color: #FFB755;
    text-transform: capitalize;
    font-weight: 700;
    margin-top: 1.5rem;
}

.number_tm p {
    font-weight: 400;
    width: 75%;
    text-align: center;
    color: #364B7F;
    margin: 0px;
}

.increase_investment h6 {
    color: #555CFF;

}

.more_details {
    margin-top: 0.5rem;
}

.more_details a {
    font-size: 0.813rem;
    color: #364B7F;
}


.trand_heading h1 {
    text-transform: capitalize;
    color: #364B7F;
    font-size: 1.688rem;
    font-weight: 700;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    color: #fff !important;
    background-color: #364B7F !important;
    border-color: #364B7F !important;
}

.btn-primary {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #364B7F !important;
}

.btn:focus {
    box-shadow: none !important;
    outline: 0 !important;
}

.btn {
    height: 2rem !important;
    padding: 0px 1.688rem !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    line-height: 2 !important;
    border-radius: 0.613rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fa-calendar-days {
    font-size: 1.563rem;
    margin-top: 0.2rem;
    margin-left: 0.5rem;
    color: #364B7F;
}



/* login page css */


.login {
    margin: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url('../images/Frame.png');
    background-size: cover;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
}


.width {
    /* width: 380px !important; */
    width: 50% !important;
}

.width2 {
    /* width: 437px !important; */
    width: 50% !important;
}

.width2 img {
    width: 150%;
    height: 120px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_form {
    padding: 0px;
    margin: 0px;
}

.login_heading p {
    text-transform: capitalize;
    font-size: 1.25rem;
    margin-bottom: 1rem !important;
    margin: auto;
    text-align: center;
}

.login_label {
    margin: 0px !important;
    font-size: 0.85rem !important;
    margin-left: 0.2rem !important;
}

.login_input {
    width: 320px !important;
    border-radius: 22px !important;
    /* height: 40px; */
    margin: 0px !important;
    padding: 8px 10px !important;
}

.login_input::placeholder {
    font-size: 0.875rem;
    color: #595959;
}

.forgot a {
    font-size: 0.75rem;
    color: #000;
    text-decoration: none;
}

.signin_btn {
    border: 0;
    background-color: #364B7F;
    color: #fff;
    width: 150px;
    font-weight: 600 !important;
    height: 30px;
    border-radius: 22px;
    font-size: 0.858rem;
    margin: 0px !important;
    padding-bottom: 0rem !important;
}


/* model css */
/* .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: 0 !important;
    border-radius: 0px !important;
    outline: 0;
} */

/* .modal-body {
    height: 100vh;
    overflow-y: scroll;
} */
.custom_body {
    height: 80vh;
    overflow-y: scroll;
}

.modal-body::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 2px grey; */
    border-radius: 10px;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
    background: #8b8b8b;
}

/* .close_btn {
    background-color: #007BFC;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-top: -10px;
    margin-right: -10px;
} */

/* .btn-close:focus {
    box-shadow: none !important;
} */

/* .btn-close {
    background: url('../images/multiply-svgrepo-com.svg') center/1em auto no-repeat !important;
    font-size: 1.063rem;
    opacity: 1 !important;
    color: #fff !important;
} */

.modal_hading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lock_icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid rgb(197, 197, 197);
    display: flex;
    justify-content: center;
    align-items: center;
}

.lock_icon .fa {
    font-size: 1.188rem;
}

.modal_hading h1 {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    margin-top: 1rem;
}

.modal_hading p {
    font-size: 0.77rem;
    text-align: center;
    margin: 0px;
}

.custom-shadow {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #d4d4d4 !important;
    border-radius: 5px !important;
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" fill="%23523436"/></svg>');
    background-repeat: no-repeat;
    background-position: 2% center;
    padding-left: 30px !important;
    padding: 7px 20px;
    width: 100%;
}

.custom-shadow:hover {
    border: 1px solid #333333 !important;

}

.custom-shadow:focus {
    outline: 2px solid #2D9CDB !important;
    border: 0px !important;

}

/* 
.input-group-text {
    background-color: transparent !important;
} */
/* 

.input-group-text {
    border: 0px !important;
    padding: 0.375rem 0.5rem;
    color: #523436 !important;
} */

.custom-shadow {}

/* .custom {
    border: 0px !important;
    padding-left: 0px !important;
} */



.bank_details {
    background-color: transparent;
    display: flex;
    align-items: center;
    border: 0;
    outline: 0;
    width: 100%;
}


.bank_details:focus {
    box-shadow: none !important;
    border: 0;
    outline: 0;
}

.bank_image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bank_name {
    margin-left: 1rem;
}


.bank_name h6 {
    margin: 0px;
    font-size: 0.813rem;
    text-align: left;
    font-weight: 600;
    text-transform: capitalize;
}

.bank_name p {
    margin: 0px;
    font-size: 0.75rem;
    text-align: left;

}

.bank_name p span {
    color: red;
}

.fa-chevron-right {
    color: #b9b9b9;
}

.bank_logo_custom {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 63px;
}

.bank_icon {
    height: 60%;
    margin: 0px;
}

.logo_width {
    height: 100%;
    width: 150px;
    margin: 0px;
    display: flex;
    align-items: center;
}

/* 
.bank_logo_custom p {
    margin: 0px !important;
} */


.bank_logo_custom img {
    width: 100%;
    height: 100%;
}

.Btn_dropdown {
    position: relative !important;
}

.wrapper-btn {
    position: absolute;
    bottom: -3.5rem;
    left: -8rem;
    width: 125px;
    height: 100px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.wrapper-btn button {
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    font-size: 0.875rem;
}

.trash {
    margin-left: -0.5rem;
}

.del {
    margin-left: 0.35rem;
    margin-top: 0.2rem;
}

/* DASHBOARD MODEL CSS */

.flow_chart {
    width: 80%;
    height: 85vh;
    border: 5px solid #364B7F;
    background-color: #fff;
    position: absolute;
    left: 10%;
    top: 7%;
    display: flex;
    align-items: center;
    z-index: 10;
}

.full_chart {
    width: 100%;
    height: 90%;
}

.chart_widget {
    width: 80%;
    height: 40px;
}

.wediget {
    width: 24%;
    height: 100%;
    background-color: #364B7F;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wediget2 {
    width: 28%;
    height: 100%;
    background-color: #4C5F8D;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wediget4 {
    width: 20%;
    height: 100%;
    background-color: #E9EBF1;
    color: #364B7F;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wediget3 {
    border-left: 2px solid #364B7F;
}

.wediget p {
    font-size: 1.25rem;
    font-weight: 600;
    margin: auto;
    text-transform: capitalize;
}

.wediget2 p {
    font-size: 0.875rem;
    font-weight: 600;
    margin: auto;
    text-transform: capitalize;
}

.wediget4 p {
    font-size: 1.25rem;
    font-weight: 600;
    margin: auto;
    text-transform: capitalize;
}

.form-check-input:focus {
    box-shadow: none !important;
    border: 0 !important;
}

.form-check-input:checked {
    background-color: #fff !important;
    background-image: url('../images/check.svg') !important;
    border: 0 !important;
}


.form-check-input[type=checkbox] {
    border-radius: 0px !important;
}

.chart_silder {
    width: 100%;
    height: calc(90% - 8px);
    margin-top: 0.5rem;
}

.chart_one {
    width: 60%;
    height: 100%;
    overflow-y: hidden;
    background-color: #E9EBF1;
}

.chart_two {
    width: 40%;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
}

.accordion {
    width: 97% !important;
    margin: auto !important;
}

.accordion2 {
    width: 100% !important;
    margin-left: 10px !important;

}

.accordion2 .accordion-item {
    border-radius: 14px 0px 0px 14px !important;
}

.accordion-item {
    border-radius: 8px !important;
}

.accordion-button {
    height: 30px !important;
    background-color: #364B7F !important;
    color: #fff !important;
    border-radius: 8px !important;
    margin-top: 0.5rem !important;
    margin: auto !important;

}


.accordion2 .accordion-button {
    height: 50px !important;
    font-size: 1.625rem !important;
    font-weight: 500;
    border-radius: 14px 0px 0px 14px !important;

}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button::after {
    transform: scale(1) !important;
    background-image: url('../images/caret-up-svgrepo-com.svg') !important;
    transition: all 0.5s ease-in-out !important;

}

.accordion-button:not(.collapsed)::after {
    transition: all 0.5s ease-in-out !important;
    transform: rotate(180deg) !important;
}


.mortage_div {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 60%;
}


.mortage_div .color {
    width: 15px;
    height: 15px;
    background-color: #FCB196;
    border-radius: 50%;
}

.mortage_div_2 .color {
    background-color: #FFC6EC;
}

.mortage_div_3 .color {
    background-color: #FF888B;
}

.mortage_div_4 .color {
    background-color: #CBACAA;
}

.mortage_div_5 .color {
    background-color: #FFAD05;
}

.mortage_div_6 .color {
    background-color: #88F2C9;
}

.mortage_div_7 .color {
    background-color: #46EB0D;
}

.mortage_div_8 .color {
    background-color: #C87EFC;
}

.mortage_div_9 .color {
    background-color: #FFFF99;
}

.mortage_div_10 .color {
    background-color: #E4D3F9;
}

.text_chart {
    color: #fff;
    border-left: 1px solid #4C5F8D;
    padding-left: 0.5rem;
    margin: 0px;
}

.mortage_div p {
    color: #FCB196;
    margin: 0px;
}

.auto_text_2 {
    margin-right: 1.65rem;
}

.auto_text_3 {
    margin-right: 1.55rem;
}

.auto_text_4 {
    margin-right: 2.75rem;
}

.auto_text_5 {
    margin-right: 3.6rem;
}

.auto_text_6 {
    margin-right: 7.2rem;
}

.auto_text_7 {
    margin-right: 9.9rem;
}

.auto_text_8 {
    margin-right: 7.6rem;
}

.auto_text_9 {
    margin-right: 0.75rem;
}

.auto_text_10 {
    margin-right: 4.15rem;
}

.mortage_div_2 p {
    color: #FFC6EC;
}

.mortage_div_3 p {
    color: #FF888B;
}

.mortage_div_4 p {
    color: #CBACAA;
}

.mortage_div_5 p {
    color: #FFAD05;
}

.mortage_div_6 p {
    color: #88F2C9;
}

.mortage_div_7 p {
    color: #46EB0D;
}

.mortage_div_8 p {
    color: #C87EFC;
}

.mortage_div_9 p {
    color: #FFFF99;
}

.mortage_div_10 p {
    color: #E4D3F9;
}

.accordion-item {
    background-color: #364B7F !important;
    margin-top: 0.4rem;
}


.accordion-collapse {
    transition: height 0.5s ease-in-out !important;
}

.accordion-body {
    padding: 0px !important;
    height: auto !important;
    margin: 0px !important;
}

.table2 {
    width: 100% !important;
}

.table2 tbody tr:nth-child(even) {
    background-color: #364B7F;
    color: #fff;
}

.table2 tbody tr:nth-child(odd) {
    background-color: #475B8A;
    color: #fff;
}

.table2 thead th,
.table2 tbody td {
    border: 0 !important;
}

.chart_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    margin-top: 2.5rem;
}

.chart_img img {
    width: 300px !important;
}

.percent-input-container {
    position: relative;
    /* display: flex; */
}

.percent-symbol {
    position: absolute;
    right: 10px;
    /* Adjust this value as needed to position the percent symbol */
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    color: #777;
}

.dollar-input-container {
    position: relative;
    /* display: flex; */
}



.dollar-sign {
    position: absolute;
    left: 10px;
    /* Adjust this value as needed to position the dollar sign */
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    color: #777;
}


/* entity page */
.sub_main_entity {
    width: 88%;
    height: auto;
    margin: 37px auto;
}

.entity_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.entity_heading h4 {
    font-size: 1.375rem;
    color: #364B7F;
    font-weight: 700;
}

.entity_heading button {
    background-color: transparent;
    width: 220px;
    height: 55px;
    border: 2px solid #364B7F;
    border-radius: 30px;
    color: #364B7F;
    font-size: 1.125rem;
    font-weight: 700;
}

.entity_heading button:focus {
    box-shadow: none;
    border: 2px solid #364B7F !important;
    outline: 0;
}

.entity_table {
    background-color: #E9EBF1;
    width: 100% !important;
    /* height: 14.6rem; */
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 9px;
    padding-bottom: 9px;
}

.table3 {
    width: 98% !important;
    margin: 0px !important;
}

.table3 thead tr th {
    padding: 15px 12px !important;
}

.table3 tbody tr {
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #E6E5EE !important;
}

.table3 tbody tr td {
    padding: 15px 12px !important;
    border: 0 !important;
}

.table3 thead tr th:first-child {
    border-radius: 5px 0px 0px 0px;
}

.table3 thead tr th:last-child {
    border-radius: 0px 05px 0px 0px;
}

/* setting page */

.setting {
    width: 100%;
    height: 100vh;
    margin: auto;
}

.setting_form {
    width: 100%;
    height: 38rem;
    margin-top: 1.5rem;
}

.setting_subForm {
    width: 88%;
    margin: auto;
    height: 15rem;
}

.setting_subForm_2 {
    height: 19rem;
}

.setting_subForm .row {
    --bs-gutter-x: 0px !important;
    height: 100%;

}

.profile_update {
    margin-top: 1.5rem;
    border-bottom: 2px solid #364B7F;
    padding-bottom: 1rem;
    width: 90%;
}

.profile_update h4 {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0px;
    text-transform: capitalize;

}

.profile_update p {
    font-size: 0.813rem;
    font-weight: 500;
    margin: 0px;
    color: #696464;
    width: 90%;
}


.setting_formUpdate {
    width: 90%;
    /* height: 12rem; */
    border-radius: 5px;
    margin-top: 2rem;
}

.setting_form_input .form-label {
    font-size: 0.75rem;
    margin: 0px;
    color: #000 !important;
    font-weight: 600;
}

.setting_form_input .form-control {
    font-size: 0.75rem;
    margin: 0px;
    color: #000 !important;
    font-weight: 600;
    /* width: 95% !important; */
    height: 30px;
    border-radius: 5px;
}

.save_btn {
    display: flex;
    justify-content: center;
}

.save_btn button {
    width: 6rem;
    height: 2.25rem;
    background-color:
        #022A30;
    border: 0 !important;
    color: #fff;
    border-radius: 5px;
    font-size: 0.913rem;
    font-weight: 600;

}

.additional_setting h2 {
    font-size: 1.188rem;
    font-weight: 700;
}

.additional_setting p {
    font-size: 0.875rem;
}

.current_device h6 {
    margin: 0px;
    font-size: 0.613rem;
    font-weight: 700;
}

.current_device p {
    margin: 0px;
    font-size: 0.563rem;
    font-weight: 600;

}

.current_device p span {
    color: rgb(17, 202, 17);
    font-weight: 700;

}

.save_btn_2 {
    margin-top: 0.8rem;
}

.save_btn_2 button {
    width: 22rem;
    height: 2.25rem;
    background-color:
        #022A30;
    border: 0 !important;
    color: #fff;
    border-radius: 5px;
    font-size: 0.913rem;
    font-weight: 600;
    text-transform: uppercase;
}

.save_btn_3 button {
    width: 12rem;
    height: 2.25rem;
    background-color:
        #eb0f0f;
    border: 0 !important;
    color: #fff;
    border-radius: 5px;
    font-size: 0.913rem;
    font-weight: 600;
    text-transform: uppercase;
}

/* manual input */
.create_manual_input {
    width: 250px;
    height: 55px;
    border: 2px solid #364B7F;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.create_manual_input:hover {
    background-color: rgb(223, 230, 248);
}

.create_manual_input a {
    text-decoration: none;
    color: #364B7F;
    font-size: 1.125rem;
    font-weight: 700;
    width: 231px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.custom-dropdown .dropdown-toggle::after {
    display: none;
}

.custom-dropdown button {
    border: none;
    padding: 0 !important;
    height: 22px !important;
    font-size: 17px !important;
}

.custom-dropdown .btn.show {
    background: none !important;
}

.dollar-input-container input {
    padding-left: 23px;
}

.custom_dashboard_btn_trends {
    color: #fff !important;
    background-color: #364B7F !important;
    border-color: #364B7F !important;
}

/* .custom-width-cash-card{
    width: 40% !important;
} */
/* .custom-collapse-manualinput{
    background: rgb(255, 255, 255);
    border-radius: 3px;
    border: 1px solid black !important;
} */

.cCard.accordion-item {
    background: #fff !important;
    border: solid 3px #364B7F;
    border-radius: 15px !important;
    overflow: hidden;
}

.cCard .accordion-button {
    height: 46px !important;
    padding: 0 20px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    font-size: 23px;
}

.cCard .accordion-collapse {
    padding: 20px;
}

.cCard .accordion-collapse span {
    font-size: 38px;
    font-weight: 800;
}

.cCard .accordion-button::after {
    width: 18px;
    height: 18px;
    background-position: center;
    background-size: contain;
}


.sub_main_insight {
    width: 88%;
    height: auto;
    margin: 40px auto;
}

.sub_main_insight_header {
    display: flex;
    justify-content: space-between;
}

.sub_main_insight_text h1 {
    font-size: 1.375rem;
    color: #364B7F;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0px;
    padding-bottom: 5px;
}

.sub_main_insight_text p {
    font-size: 1.125rem;
    color: #F36C26;
    font-weight: 600;
    margin: 0px;
    padding: 7px 0px;
}

.sub_main_insight_text h6 {
    font-size: 0.875rem;
    color: #364B7F;
    font-weight: 600;
}

.sub_main_insight_header button {
    background-color: transparent;
    width: 270px;
    height: 55px;
    border: 2px solid #364B7F;
    border-radius: 30px;
    color: #364B7F;
    font-size: 1.125rem;
    font-weight: 700;
    margin-top: 5px;
}

.sub_main_insight_banking {
    display: flex;
    gap: 60px;
    margin-top: 40px;
    width: 100%;
}

.banking-img {
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.banking-img::before {
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover !important;
    background: url('../images/insights_one.png');
    z-index: 11 !important;
}

.banking-img img {
    width: 100%;
}

.banking-img::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 60%;
    width: 100%;
    background-color: rgba(54, 75, 127, 0.8);
}

.banking-img-2::after {
    height: 70%;
}

.banking-img-3::after {
    height: 100%;
}

.banking-img span {
    position: absolute;
    z-index: 11;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100%;
}

.banking-img span h2 {
    color: #F36C26;
    text-align: center;
    font-weight: 700;
    font-size: 1.875rem;
    margin-top: 30px;
}

.banking-img span p {
    color: #C3D831;
    text-align: center;
    font-weight: 600;
    font-size: 1.125rem;
    margin-top: 30px;

}

.sub_main_insight_account {
    width: 100%;
    height: auto;
    background-color: #E9EBF1;
    border-radius: 22px;
    margin-top: 10px;

}

.insight_account_wrapper {
    padding: 10px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.insight_account {
    border: 2px solid #364B7F;
    width: 25%;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 3px rgba(188, 195, 213, 0.9);
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.insight_account p {
    margin: 0px;
    opacity: 0.9;
    padding-bottom: 4px;
}

.insight_account h6 {
    margin: 0px;
    color: #364B7F;
    font-weight: 700;
    font-size: 1.125rem;
}

.insight_account_text p {
    color: #364B7F;
    text-align: center;
    margin: 0px;
    padding: 10px 0px;
}

.insight_account_text p span {
    color: #F36C26;
}

.insight_account_value {
    display: flex;
    gap: 30px;
    padding: 0px 20px;
    padding-bottom: 15px;
}

.insight_account_value_1 {
    width: 50%;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.9);
    height: 150px;
    padding: 30px;
    background-color: #364B7F;
}

.insight_account_value_2 {
    display: flex;
    align-items: center;
}

.insight_account_value_1 h1 {
    color: #fff;
    margin: 0px;
    font-size: 1.575rem;
    font-weight: 700;
}

.insight_account_value_1 p {
    color: #fff;
    margin: 0px;
    font-size: 1.125rem;
    font-weight: 300;
    position: relative;
    width: 230px;
    padding-bottom: 7px;
}

.insight_account_value_1 p::after {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 2px;
    background-color: #C3D831;
    border-radius: 5px;
}

.insight_account_value_1 h6 {
    color: #fff;
    margin: 0px;
    font-size: 1.125rem;
    font-weight: 300;
    position: relative;
    width: 230px;
    padding-top: 7px;
}

.insight_account_value_2 p {
    width: 80px;
}

.account_value {
    transform: translateY(13px) translateX(-59%);
}

/* PageNotFound.css */
.page-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f8f8;
}

.page-not-found .container {
    text-align: center;
}

.page-not-found h1 {
    font-size: 6rem;
    margin: 0;
}

.page-not-found p {
    font-size: 1.5rem;
    margin-top: 0.5rem;
}

.go-to-login {
    width: 250px;
    height: 55px;
    border: 2px solid #364B7F;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

/* admin page */
.main_client {
    width: 88%;
    height: auto;
    margin: 40px auto;
}

.main_client_btn {
    margin-left: auto;
    display: flex;
    justify-content: end;
    margin-bottom: 1.5rem;
}

.main_client_btn button {
    background-color: #364B7F;
    width: 220px;
    height: 50px;
    border: 2px solid #364B7F;
    border-radius: 30px;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 700;
}

.main_client_header {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
}

#basic-addon1 {
    background-color: #1890FF;
    color: #fff;
    border-radius: 0px 8px 8px 0px;
}

.form-input,
.input-group {
    border: 1px solid #364B7F !important;
    border-radius: 8px !important;
}

.form-input {
    background-color: #f6f6f6 !important;
}

.input-group {
    margin-top: 12px;
}

#form-control {
    border: 0px;
    border-radius: 8px 0px 0px 8px !important;
}

#form-control::placeholder {
    color: rgba(188, 195, 213, 0.9);
}

/* .form-select {
    height: 37px;
    width: 100px !important;
    background-color: transparent !important;
} */

.form-select:focus {
    border: 1px solid #364B7F !important;

}

.form-check-input.form-radio {
    border: 1px solid #364B7F !important;
    background-image: none !important;
    border-radius: 100px !important;
}

.form-check-input.form-radio:checked {
    background-color: #fff !important;
    background-image: none !important;
    border: 5px solid #364B7F !important;
}

.main_client_main {
    width: 100%;
    background-color: #364B7F;
    box-shadow: 0px 0px 0px 8px rgb(233, 235, 241);
    height: auto;
    border-radius: 10px;
    margin-top: 1rem;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.client_main_wrapper {
    width: 100%;
    height: 80px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 0px 4px rgb(233, 235, 241);
    padding: 0px 20px;
}


.client_wrapper_text {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 22%;
}

.client_wrapper_text::after {
    content: '';
    display: block;
    position: absolute;
    right: 0px;
    top: 12.5%;
    height: 75%;
    width: 3px;
    background-color: #364B7F;
    border-radius: 30px;
}

.client_wrapper_text h1 {
    font-size: 1.25rem;
    color: #364B7F;
    text-transform: capitalize;
    margin: 0px;
}

.client_wrapper_text p {
    font-size: 0.75rem;
    color: #364B7F;
    margin: 0px;
}

.client_wrapper_amount h1 {
    font-size: 1.125rem;
    color: #F36C26;
    text-transform: capitalize;
    margin: 0px;
}

.client_wrapper_amount p {
    font-size: 1rem;
    color: #364B7F;
    margin: 0px;
    font-weight: 700;
}

.client_wrapper_amount_2 P {
    font-weight: 400;
}

.client_wrapper_amount p span {
    color: #0099FD;
    background-color: #EDF6F9;
    border-radius: 10px;
    padding: 2px;
}


.client_wrapper_btn button {
    border: 2px solid #364B7F;
    background-color: transparent;
    color: #364B7F;
    border-radius: 10px;
    text-transform: capitalize;
    width: 170px;
    height: 40px;
    box-shadow: 0px 0px 0px 3px rgb(233, 235, 241);
    outline: 0;
}

.client_wrapper_btn_2 button {
    border: 2px solid #30B8FF;
    background-color: #F1FAFF;
    color: #30B8FF;
}



.client_wrapper_btn_3 button {
    border: 2px solid #FF888B;
    background-color: #FFF5F6;
    color: #FF888B;
}


.main_client_footer {
    background-color: #E9EBF1;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    margin: 0px;
}

.main_client_footer p {
    margin: 0px;
    font-size: 1.375rem;
    font-weight: 700;
    color: #F36C26;
    text-transform: capitalize;
}

.main_client_footer p span {
    color: #364B7F;
}


.page-link {
    background-color: transparent !important;
    color: #364B7F !important;
    opacity: .5;
    border: 0 !important;
    box-shadow: none;
    padding: 0px 7px !important;
}

.page-item.link-frist {
    background-color: #d5d9e4 !important;
}

.page-link:focus {
    border: 0 !important;
    box-shadow: none !important;
    outline: 0 !important;
}

.page-link:active,
.page-link.active {
    opacity: 1;
}

.client_footer {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
}

.form-select-2 {
    border: 2px solid #c0cdd9 !important;
    height: 40px;
    width: 190px !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    color: #003468 !important;
    font-weight: 400 !important;
}

.form-select-2:focus {
    border: 2px solid #c0cdd9 !important;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/* react toogle css */
.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
}

.react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
}

.trouble-shoot-custom {
    font-weight: bold;
}

.clientLogo {
    background: #fff;
    color: #000;
    padding: 35px;
    margin-top: 32px;
    margin-bottom: 25px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    width: 70%;
    height: 110px;
    margin-left: auto;
    margin-right: auto;
}